import { defineStore } from 'pinia'

// quick and dirty bomb
const debugMode = false;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      userEmail: null,
      userName: null,
      userAddress: null,
      userPhone: null,
      userImage: null,
      userRole: null,
      userCompany: null,
      scanningStations: [],
      scanningCollections: [
        {
          'id': 1,
          'name': 'Collection 7'
        },
      ],
      availableApps: [],
      myemail: "",
      manageremail: "",
      selectedApps: [],
      logos: [
        {
          name: "Only",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/logos/only.png",
        },
        {
          name: "ONLY&SONS",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/oliver/logos/ons.png",
        },
        {
          name: "KIDS ONLY",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/oliver/logos/onk.png",
        },
        {
          name: "ONLY PLAY",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/oliver/logos/onp.png",
        },
        {
          name: "JDY",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/oliver/logos/jdy.png",
        },
        {
          name: "CARMAKOMA",
          url: "https://storage.googleapis.com/only-bi-bd-cdn/oliver/logos/car.png",
        },
      ],
    }
  },
  actions: {
    async fetchApps() {
      const response = await fetch(this.backendApi + "/apps");
      const data = await response.json();

      this.availableApps = data.resultset;

      return;
    },
    async getBeaconStations() {
      this.scanningStations = [];
      const res = await fetch(this.backendApi + "/scannertool/beacon");
      const data = await res.json();
     
      data.forEach(i => {
          this.scanningStations.push(i);
      });
    },
    async requestAccess() {
       const response = await fetch(this.backendApi + "/add-user", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          myemail: this.myemail,
          manageremail: this.manageremail,
          selectedApps: this.selectedApps,
        })
      });

      const data = await response.json();

      return data;
    }
  }
})