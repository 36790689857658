<template>
  <div v-show="updateExists" class="p-2 bg-yellow text-black font-bold text-center">
    <button @click="refreshApp">
      An update is available.. Update now by clicking this banner :)
    </button>
  </div>
  <div class="flex min-h-screen" v-if="!emailSent">
    <div class="py-2 px-8 bg-gray-light mobile:w-full">
      <div class="mb-4">
        <h1 class="text-xl font-bold">Request access to ONLY APPs:</h1>
      </div>
      <div class="mb-4">
        <label for="myemail">My email address:</label>
        <input
          type="text"
          v-model="user.myemail"
          placeholder="My email address"
          class="w-full p-2 border border-gray rounded"
        />
      </div>
      <div>
        <label for="myemail">My managers email address:</label>
        <input
          type="text"
          v-model="user.manageremail"
          placeholder="Managers email address"
          class="w-full p-2 border border-gray rounded"
        />
      </div>

      <div class="mt-4">
        <label for="myemail">Requesting access to one (or more) apps:</label>

        <div v-for="app in user.availableApps" :key="app">
          <input
            type="checkbox"
            :id="app.p_id"
            placeholder="Select an app"
            v-model="user.selectedApps"
            :value="app.p_id"
            class=""
          />
          <label class="ml-2" :for="app.p_id">{{ app.name }}</label>
        </div>

        <!-- <select
          v-model="user.selectedApp"
          class="w-full p-2 border border-gray rounded"
          @change="changeApp()"
        >
          <option v-for="app in user.availableApps" :key="app" :value="app.p_id">
            {{ app.name }}
          </option>
        </select> -->
      </div>

      <div class="mt-4">
        <button @click="requestAccess" class="bg-green text-white p-2 rounded">
          Request access
        </button>
      </div>

      <div class="mt-4" v-if="bullets.length > 0">
        <h1 class="text-xl font-bold">App Highlights:</h1>
        <ul>
          <li v-for="bullet in bullets" :key="bullet">- {{ bullet }}</li>
        </ul>
      </div>
    </div>

    <div
      class="p-16 bg-gray-dark mobile:hidden tablet:visible tablet:w-full desktop:visible desktop:w-full"
    >
      <div v-for="logo in user.logos" :key="logo.name" class="pb-16">
        <img width="200" :src="logo.url" alt="logo" />
      </div>
    </div>
  </div>
  <div class="flex min-h-screen" v-if="emailSent">
    <div class="py-2 px-8 bg-gray-light">
      <div class="mb-4">
        <h1 class="text-xl font-bold">Request was sent successfully!</h1>
        <br />
        <p class="text-sm">Please wait for your manager to approve your request.</p>
        <br />
        <p class="text-sm">
          You will receive an email once your request has been approved with further
          instructions.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import update from "@/mixins/update";
import { useSettings } from "@/store/user";
import { storeToRefs } from "pinia";

export default {
  name: "App",
  setup() {
    const user = useSettings();
    user.fetchApps();

    const { fetchApps } = storeToRefs(user);

    return { user, fetchApps };
  },
  mixins: [update],
  data() {
    return {
      emailSent: false,
      bullets: [],
    };
  },
  methods: {
    async checkOut(id) {
      this.checks.push(id);
    },
    async changeApp() {
      this.bullets = [];

      this.user.availableApps.forEach((app) => {
        if (app.p_id === this.user.selectedApp) {
          this.bullets = app.bullets.split(",");
        }
      });
    },
    async requestAccess() {
      if (
        !this.user.myemail ||
        !this.user.manageremail ||
        !this.user.selectedApps.length > 0
      ) {
        alert("Please fill in all fields");
        return;
      } else if (this.user.myemail === this.user.manageremail) {
        alert("Manager email cannot be the same as your email");
        return;
      } else if (
        !this.user.myemail.includes("@") ||
        !this.user.manageremail.includes("@") ||
        !this.user.myemail.includes(".") ||
        !this.user.manageremail.includes(".")
      ) {
        alert("Please enter a valid email address");
        return;
      } else {
        this.user.requestAccess();
        this.emailSent = true;
      }
    },
  },
};
</script>

<style></style>
