<template>
  <h1>Start scanning styles</h1>

  TEST
</template>

<script>
import { useSettings } from "@/store/user";
// import { storeToRefs } from 'pinia'
import mixins from "@/components/mixins";

export default {
  mixins: [mixins],
  setup() {
    const user = useSettings();

    // const {  } = storeToRefs(user)

    return { user };
  },
  components: {},
  methods: {},
};
</script>
